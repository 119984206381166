import React, { Component } from "react";
import { graphql } from "gatsby";
import Header from "../components/header/header";
import Head from "../components/head/head";
import Footer from "../components/footer/footer";
import Thankyou from "../components/thankyou/thankyou";
import CookieConsent from "../components/cookieConsent/cookieConsent";
import { getParams } from '../utils/analytics/UtmReferrers';
require('../styles/global.css');

class ThankYouPage extends Component {

    constructor(props) {
        super(props);
        if (props.location.state) {
            var language = props.location.state.locale;
        }

        this.state = {
            language: language ? language : 'zh-CN'
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            var _satellite = window._satellite

            if (_satellite) {
                _satellite.track("regStart");
            }
        }
    }

    handler(field, value) {
        this.setState({
            [field]: value
        })
    }


    render() {
        let domain;
        const digitalDataTitle = "ca-thank-you";

        if (typeof window !== 'undefined') {
            /**
             * pulls domain buyer name
             */
            domain = window.location.href;

            //passes domain to getParams util to set UTM parameters for Google Analytics to pick up
            getParams(domain);
        }
        const nodes = this.props.data.allBuyerList.edges[0].node;
        const relationshipData = nodes.relationship;
        let buyerData = nodes.buyer;
        let localeContent = nodes.content[0];
        let localePhone = localeContent.localePhoneNumber;
        var localeForm = nodes.formContent[0];
        var langCode = this.state.language;

        localeContent = nodes.content.find(
            content => content.contentLocale === langCode
        );

        if (!localeContent) {
            localeContent = nodes.content.find(
                content => content.contentLocale.split('-')[0] === langCode.split('-')[0]
            );
        }

        localeForm = nodes.formContent.find(
            formContent => formContent.contentLocale === langCode
        );

        if (!localeForm) {
            localeForm = nodes.formContent.find(
                formContent => formContent.contentLocale.split('-')[0] === langCode.split('-')[0]
            );
        }


        var thankYouContent = nodes.thankYouContent.find(
            content => content.contentLocale === langCode
        )

        if (!thankYouContent) {
            thankYouContent = nodes.thankYouContent.find(
                content => content.contentLocale.split('-')[0] === langCode.split('-')[0]
            )
        }


        return (
            <div id="buyer-microsite">
                <Head
                    buyer={buyerData}
                    locale={this.state.language}
                    digitalDataTitle={digitalDataTitle} />
                <Header
                    handler={this.handler.bind(this)}
                    buyer={buyerData}
                    localeContent={relationshipData}
                    phone={localePhone}
                    locale={this.state.language}
                    content={localeContent ? localeContent : nodes.content[0]}
                    params={this.props.location} />
                <Thankyou
                    buyer={buyerData}
                    form={localeForm}
                    content={localeContent}
                    phone={localePhone}
                    thanksContent={thankYouContent} />
                <Footer
                    buyerInfo={buyerData}
                    content={localeContent ? localeContent : nodes.content[0]} />
                <CookieConsent content={localeContent ? localeContent : nodes.content[0]} />
            </div>
        );
    }
}

export default ThankYouPage

export const query = graphql`
query($permalink: String ) {
  allBuyerList(filter: {buyer: {permalink: {eq: $permalink}}}) {
    edges {
      node {
        ...Buyer
        ...Relationship
        ...Content
        ...Form
        ...ThankYou
      }
    }
  }
}
`
